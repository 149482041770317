<form (submit)="handleForm($event)">
  <div style="margin-bottom: 7px;">
    <div #paymentRequestButton class="applePayButton"></div>

    <div class="st-elements-qbe">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px" style="margin-bottom: 5px;">
        <label fxFlex="50%" fxFlex.lt-sm="">
          <div class="st-label">Card</div>
          <div id="card-number-element" #cardNumberElement></div>
        </label>
        <label fxFlex="50%" fxFlex.lt-sm="">
          <div class="st-label">Exp</div>
          <div id="card-expiry-element" #cardExpElement></div>
        </label>
      </div>

      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="5px">
        <label fxFlex="50%" fxFlex.lt-sm="">
          <div class="st-label">CVC</div>
          <div id="card-cvc-element" #cardCvcElement></div>
        </label>
        <label fxFlex="50%" fxFlex.lt-sm="">
          <div class="st-label">Zip</div>
          <input id="postal-code" #cardPostalCode class="field-zip" mask="00000" placeholder="Zip"/>
        </label>
      </div>
    </div>

    <div id="card-element" #cardElement>
      <!-- A Stripe Element will be inserted here. -->
    </div>

    <!-- Used to display Element errors. -->
    <p class="has-text-danger">{{ cardErrors }}</p>
  </div>
  <hr>
  <div>
    <mat-checkbox [formControl]="termsAgreementControl"
                  color="primary"
                  labelPosition="after"
                  class="mat-checkbox-confirm"
    >
      <span (click)="onAgreementTextClick($event)"
            [innerHTML]="'form.checkout_agree_to_terms' | translate"></span>
      <mat-error *ngIf="termsAgreementControlError">{{ 'form.checkout_agree_to_terms_error' | translate }}</mat-error>
    </mat-checkbox>
  </div>
  <div class="guarantee__btns-container" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="stretch">
    <button mat-button class="mat-btn" [disabled]="loading || disabledBtn" [class.spinner]="loading">{{ 'total_premium.purchase' | translate }}</button>
    <button mat-button type="reset" class="mat-btn outline" [disabled]="loading" *ngIf="hasBackButton" (click)="decline()">{{ 'form.go_back' | translate }}</button>
  </div>
  <div class="loading-notify" *ngIf="loading">
    {{ 'disclosure.your_payment_is_processing' | translate }}
  </div>

</form>



<pre *ngIf="confirmation" style="max-width: 500px;">
  {{ confirmation | json }}
</pre>
